// Main Color Palatte
$background: #3b4252;
$main-text: white;
$shadow: #2e3440;

$input-background: #e5e9f0;
$input-background--focus: white;

$button-text: white;
$button-background: #d08770;
$button-background--hover: #ebcb8b;

$card-background: #eceff4;

$container-background: #a3be8c;
$container-background--active: #c0e0a4;
$container-background-remove: #bf616a;
$container-background-remove--active: #ff8591;

@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.input {
  display: flex;
  width: 90%;
  position: relative;
  align-items: center;

  @include tablet {
    width: 80%;
  }

  @include desktop {
    width: 66%;
  }

  &__box {
    width: 100%;
    border-radius: 3.125rem;
    padding: 1.25rem 1.875rem;
    font-size: 1rem;
    border: none;
    transition: 0.2s;
    background-color: $input-background;

    @include tablet {
      font-size: 1.5rem;
    }

    &:focus {
      background-color: $input-background--focus;
      box-shadow: 0 0 0.625rem 100vh rgba($shadow, 0.5);
      outline: none;
    }
  }

  &__submit {
    position: absolute;
    width: 4.125rem;
    height: 3.125rem;
    margin: 0.75rem;
    border-radius: 3.125rem;
    right: 0;
    border: none;
    font-size: 0.9375rem;
    font-weight: bold;
    background-color: $button-background;
    color: $button-text;
    transition: 0.2s all;
    cursor: pointer;

    @include tablet{
        font-size: 1.25rem;
        width: 6.125rem;
    }

    &:hover {
      background-color: $button-background--hover;
    }

    &:active {
      transform: scale(0.8);
      box-shadow: 0 0 0.3125rem $shadow;
    }
  }
}

@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.container{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-top: 14rem;
    align-items: center;
    height: 80vh;
    transition: 0.3s;

    @include tablet{
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;
        width: 90%;
    }

    @include desktop{
        width: 80%;
    }
}
.todos{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: .3125rem;
    width: 95%;
    margin-bottom: 1rem;
    background-color: $container-background;
    transition: 0.3s;
   
    @include tablet{
        padding: 2rem;
        width: 47.5%;
    }

    &--completed{
        @extend .todos;
        background-color: $container-background-remove;
    }

    &__heading{
        font-size: 1.5rem;
        color: $main-text;
        text-align: center;

        @include tablet{
            font-size: 1.8rem;
        }
    }

    &__message{
        margin-top: 1rem;
        font-size: 1.25rem;
        color: white;
        text-align: center;
    }
}

.drag-active{
    background-color: $container-background--active;
}

.drag-complete{
    background-color: $container-background-remove--active;
}

@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.todos__card{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: .3125rem;
    padding: 1.25rem;
    margin-top: .9375rem;
    background-color: $card-background;
    transition: 0.3s;

    &:hover{
        box-shadow: 0 4px 10px $shadow;
        transform: scale(1.03);
    }

    @include tablet{
        flex-direction: row;
    }

    &--text{
        flex: 1;
        padding: .3125rem;
        border: none;
        font-size: 1rem;

        @include tablet{
            font-size: 1.25rem;
        }
    }

    &--edit{
        flex: 1;
        width: 100%;
        padding: .3125rem;
        border-radius: .3125rem;
        border: none;
        background-color: rgb(200, 200, 200);
        font-size: 1.25rem;
    }

    &__icons{
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 1rem;

        @include tablet{
            justify-content: center;
            margin-top: 0rem;
            align-items: flex-end;
        }
    }

    &__icon{
        margin-left: .625rem;
        font-size: 1.5625rem;
        cursor: pointer;
        transition: 0.3s;

        &:hover{
            @extend .todos__card__icon;
            color: #5e81ac;
            scale: 1.1;
        }

        &--delete{
            @extend .todos__card__icon;
            &:hover{
                @extend .todos__card__icon;
                color: #bf616a;
                scale: 1.1;
            }
        }
    }
}

.drag{
    box-shadow: 0 0 1.25rem $shadow;
}